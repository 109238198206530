import React from 'react';
import WishList from "../../main/ModalWishlist";
import { Link } from "react-router-dom";
import CartCtx from "../../CartCtx";
import { Button } from "react-bootstrap";
import Badges from "../../main/Badges";
import { isIterableArray } from '../../block-slider/utils';

class Product extends React.Component {
  static contextType = CartCtx;
  render() {
    const product = this.props.product
    // console.log(product)
    let selectedPrice

    if (this.context.selectedCenter?.id) {
      if (isIterableArray(product.tc)) {
        selectedPrice = product.tc.find(item => item.id == this.context.selectedCenter.id)
      }
    }


    const productPrice = selectedPrice ? {
      price: selectedPrice.price,
      priceBadgeStock: selectedPrice.priceBadgeStock
    } : {
      price: product.price,
      priceBadgeStock: product.priceBadgeStock
    }

    return (
      <React.Fragment>
        {
          // product.redirect === null &&
          <div className="catalog-list__item">
            <div className="products__wrap">
              <div className="btn-toolbar">
                <WishList productId={product.id} productCount={product.count} />
              </div>
              <div className="products-image">
                <Link className="products-image__link" to={`/product/${product.name_slug}`}>
                  <img src={process.env.REACT_APP_BACKEND + 'uploads/products/main/' + product.photo_url}
                    alt={("Изображение товара " + product.name)} />
                </Link>
              </div>

              <Link className="products-title" to={`/product/${product.name_slug}`} title={product.name}>
                {product.name}
              </Link>

              <div className={("products-price " + product.sale + " " + product.stockStatus)}>
                <div className="producst-price__wrap">
                  <div className="products-price__old">
                    {productPrice.priceBadgeStock.price.discountPrice &&
                      <span
                        className="price i-rub d-inline-block">{productPrice.priceBadgeStock.price.price.toLocaleString('ru')}</span>
                    }
                  </div>
                  <div className="products-price__main d-flex align-items-end">
                    {productPrice.priceBadgeStock.price.discountPrice &&
                      <span className="price i-rub" style={{ color: '#d81137' }}>
                        {productPrice.priceBadgeStock.price.discountPrice.toLocaleString('ru')}
                      </span>
                    }
                    {!productPrice.priceBadgeStock.price.discountPrice &&
                      <span className="price i-rub">
                        {productPrice.price.toLocaleString('ru')}
                      </span>
                    }

                    {
                      product?.unit &&
                      <div className='price-unit' style={{ fontSize: 14 }}>
                        <span>
                          /
                        </span>
                        <span>
                          {product.unit}
                        </span>
                      </div>
                    }
                  </div>
                </div>
                <div className="products-cart-sm">
                  {product.count > 0 &&
                    <>
                      {!this.context.isInCart(product.id) &&
                        <CartCtx.Consumer>
                          {({ cart, addProduct }) => (
                            <Button variant="simple" className="add-to-cart" onClick={() => addProduct(product.id)}><i
                              className="i-cart" /></Button>
                          )}
                        </CartCtx.Consumer>
                      }
                      {this.context.isInCart(product.id) &&
                        <CartCtx.Consumer>
                          {({ cart, addProduct }) => (
                            <Button variant="simple" className="add-to-cart added" disabled><i
                              className="i-cart" /></Button>
                          )}
                        </CartCtx.Consumer>
                      }
                    </>
                  }
                </div>
              </div>

              <div className="products-stock">
                {product.count > 0 &&
                  <p className='stock-in'>
                    В наличии
                  </p>
                }
                {product.count < 1 &&
                  <p className='stock-out'>
                    Нет в наличии
                  </p>
                }
              </div>

              {
                productPrice.priceBadgeStock.bages.length > 0 &&
                <Badges items={productPrice.priceBadgeStock} />
              }
              {product.gift && (
                <Link to={`/product/${product.gift.name_slug}`} className="products-gift contents"
                  style={{
                    color: '#000',
                    textDecoration: 'none',
                    background: 'rgba(255,255,255,.5)',
                    backdropFilter: 'blur(20px)'
                  }}
                >
                  <span className="f7 fw500">Подарок</span>
                  <img src={process.env.REACT_APP_BACKEND + 'uploads/products/main/' + product.gift.photo_url} alt="" className="img" />
                </Link>
              )}
              <div className="products__footer">
                {process.env.REACT_APP_TEST === 1 && product.count > 0 &&
                  <>
                    {!this.context.isInCart(product.id) &&
                      <CartCtx.Consumer>
                        {({ cart, addProduct }) => (
                          <div className="cart-btn">
                            <Button className="btn btn-primary" onClick={() => addProduct(product.id)}>В корзину</Button>
                          </div>
                        )}
                      </CartCtx.Consumer>
                    }
                    {this.context.isInCart(product.id) &&
                      <CartCtx.Consumer>
                        {({ cart, addProduct }) => (
                          <div className="cart-btn">
                            <Button className="btn btn-primary added" onClick={() => addProduct(product.id)}>Уже в
                              корзине</Button>
                          </div>
                        )}
                      </CartCtx.Consumer>
                    }
                  </>
                }

                {/*<div className="short-bages">
                                                            <ul className="short-bages__wrap">
                                                                <li className="bage">
                                                                    <img src="img/temp/products/b01.png" alt="" />
                                                                </li>
                                                            </ul>
                                                        </div>*/}
                <div className="short-desc">
                  <p
                    dangerouslySetInnerHTML={{ __html: product.short_discription !== 'null' && product.short_discription }} />
                  <p>
                    {product.character.map((item, index) => {
                      return (
                        <span key={index}>
                          {item.nameChar}: {item.valueChar}; <br />
                        </span>
                      )
                    })}
                  </p>
                </div>
              </div>
            </div>
          </div>
        }
      </React.Fragment>
    );
  }
}

export default Product;