import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import CartCtx from '../CartCtx'
import CatalogColorChar from '../categories/character/catalog/CatalogColorChar'
import WishList from '../main/ModalWishlist'
import Badges from './Badges'
import logo from '../../img/logo-grey.svg'
import axios from 'axios'
import { Button } from 'react-bootstrap'
import { isIterableArray } from '../block-slider/utils'

const DropdownProductsNewProductCard = (props) => {

    const { product, context, isProductsHasVariable } = props

    const { selectedCenter } = context

    const [isLoadingCard, setIsLoadingCard] = useState(false);

    const [productState, setProductState] = useState(product)
    const [productsPrice, setProductPrice] = useState({
        price: product.price,
        priceBadgeStock: product.priceBadgeStock
    }); // цена у выбранного тц

    // если выбран тц - меняем цену 
    useEffect(() => {
        if (selectedCenter?.id) {
            if (isIterableArray(productState.tc)) {

                const selectedPrice = productState.tc.find(item => item.id == selectedCenter.id)

                setProductPrice({
                    price: selectedPrice.price,
                    priceBadgeStock: selectedPrice.priceBadgeStock
                })
            }
        }
        else if (productState) {
            setProductPrice({
                price: productState.price,
                priceBadgeStock: productState.priceBadgeStock
            })
        }
    }, [selectedCenter?.id, productState]);


    const handleSelectChar = (new_v) => {
        handleFetchNewChar(new_v)
    }

    const handleFetchNewChar = (filterSelectedLast,) => {
        setIsLoadingCard(true)
        setProductState(prev => {
            return {
                ...prev, photo_url: ''
            }
        })

        const selectedCenterId = context.selectedCenter ? context.selectedCenter.id : false

        let postData = {
            filterSelectedLast: filterSelectedLast,
            filtersSelected: [],
            product_id: productState.id,
            tc_id: selectedCenterId
        };
        // console.log('product filter postData', postData, selectedChar)
        axios.post(`${process.env.REACT_APP_API_BASE}/pages/product-filter`, postData)
            .then((response) => {
                console.log(response.status, response)

                if (response.status === 200) {
                    if (response.data.data) {


                        // const selectedDefaultChar = getChars(response.data.data.char)
                        // const selectedDefaultColor = getChars(response.data.data.color)

                        // const newChars = [...selectedDefaultChar, ...selectedDefaultColor]

                        // saveToStorage(newChars, productState.id, response.data.data.id, response.data.data.name_slug, response.data.data.category_id)

                        // setSelectedChar({
                        //     product_slug: response.data.data.name_slug,
                        //     slug: response.data.data.category_id,
                        //     product_id: response.data.data.id,
                        //     chars: newChars
                        // })
                        setProductState(prev => {
                            return {
                                ...prev, ...response.data.data
                            }
                        })
                    }
                }
            })
            .catch(err => console.log(err))
            .finally(() => {
                setIsLoadingCard(false)
            })
    }
    return (
        <div className="products-dropdown__item">
            <div className="products__wrap">
                <div className="btn-toolbar">
                    <WishList productId={productState.id} productCount={productState.count} />
                </div>
                <div className="products-image">
                    {
                        !isLoadingCard ?
                            <Link className="products-image__link" to={`/product/${productState.name_slug}`}>
                                <img src={process.env.REACT_APP_BACKEND + 'uploads/products/main/' + productState.photo_url}
                                    alt={("Изображение товара " + productState.name)} />
                            </Link>
                            :
                            <div className='logo-loader'>
                                <img src={logo} alt="" />
                            </div>
                    }
                </div>

                <CatalogColorChar
                    character={productState.color}
                    handleSelectChar={handleSelectChar}
                    isLoadingCard={isLoadingCard}
                    isProductsHasVariable={isProductsHasVariable}
                />

                <Link className="products-title" to={`/product/${productState.name_slug}`} title={productState.name}>
                    {productState.name}
                </Link>

                {/*<Link to="/SingleProduct" className="products-reviews">*/}
                {/*    /!* Классы no-rating / no-reviews для products-reviews, для добавления иконки, когда нет звездочек *!/*/}
                {/*    <StarRatings*/}
                {/*        rating={productState.rating}*/}
                {/*        starDimension="13px"*/}
                {/*        starRatedColor="#FFB800"*/}
                {/*        starSpacing="1px"*/}
                {/*    />*/}
                {/*    <i className="i-reviews"></i>*/}
                {/*    <p className="products-reviews__link">{productState.reviews}</p>*/}
                {/*</Link>*/}

                <div className={("products-price " + productState.sale + " " + productState.stockStatus)}>
                    <div className="producst-price__wrap">
                        <div className="products-price__old">
                            {productsPrice.priceBadgeStock.price.discountPrice &&
                                <span
                                    className="price i-rub d-inline-block">{productsPrice.priceBadgeStock.price.price.toLocaleString('ru')}</span>
                            }
                        </div>
                        <div className="products-price__main  d-flex align-items-end">
                            <span className="price i-rub">
                                {productsPrice.priceBadgeStock.price.discountPrice &&
                                    productsPrice.priceBadgeStock.price.discountPrice.toLocaleString('ru')
                                }
                                {!productsPrice.priceBadgeStock.price.discountPrice &&
                                    productsPrice.price.toLocaleString('ru')
                                }

                            </span>
                            {
                                productState?.unit &&
                                <div className='price-unit' style={{ fontSize: 14 }}>
                                    <span>
                                        /
                                    </span>
                                    <span>
                                        {productState.unit}
                                    </span>
                                </div>
                            }
                        </div>
                    </div>
                    {process.env.REACT_APP_TEST === 1 &&
                        <div className="products-cart-sm">
                            {productState.count > 0 &&
                                <>
                                    {!context.isInCart(productState.id) &&
                                        <CartCtx.Consumer>
                                            {({ cart, addProduct }) => (
                                                <Button variant="simple" className="add-to-cart"
                                                    onClick={() => addProduct(productState.id)}><i className="i-cart"></i></Button>
                                            )}
                                        </CartCtx.Consumer>
                                    }
                                    {context.isInCart(productState.id) &&
                                        <CartCtx.Consumer>
                                            {({ cart, addProduct }) => (
                                                <Button variant="simple" className="add-to-cart added" disabled><i
                                                    className="i-cart"></i></Button>
                                            )}
                                        </CartCtx.Consumer>
                                    }
                                </>
                            }
                        </div>
                    }
                </div>

                <div className="products-stock">
                    {productState.count > 0 &&
                        <p className='stock-in'>
                            В наличии
                        </p>
                    }
                    {productState.count < 1 &&
                        <p className='stock-out'>
                            Нет в наличии
                        </p>
                    }
                </div>
                {
                    productsPrice.priceBadgeStock.bages.length > 0 &&
                    <Badges items={productsPrice.priceBadgeStock} />
                }

                {productState.gift && (
                    <Link to={`/product/${productState.gift.name_slug}`} className="products-gift contents"
                        style={{
                            color: '#000',
                            textDecoration: 'none',
                            background: 'rgba(255,255,255,.5)',
                            backdropFilter: 'blur(20px)'
                        }}
                    >
                        <span className="f7 fw500">Подарок</span>
                        <img src={process.env.REACT_APP_BACKEND + 'uploads/products/main/' + productState.gift.photo_url}
                            alt="" className="img" />
                    </Link>
                )}

            </div>
        </div>
    )
}

export default DropdownProductsNewProductCard
